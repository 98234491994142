import React, { useState } from "react";
import tw, { css } from "twin.macro";
import { Link } from "..";
import { navs } from "../../config";
import BurgerMenu from "./BurgerMenu";
import Logo from "../../images/svg/logo.svg";
import FacebookLogo from "../../images/svg/facebook.svg";
import InstagramLogo from "../../images/svg/instagram.svg";
import TwitterLogo from "../../images/svg/twitter.svg";

const styles = {
  appointment: tw`font-normal md:text-xl uppercase transition-colors duration-200 cursor-pointer letter-spacing[1.5px] font-display`,
  transTextColours: (open: boolean, opaque: boolean) => {
    if (open) return tw`text-honeydew`;
    if (opaque) return tw`text-sonic duration-500`;
    return tw`text-white delay-1000`;
  },
  fixThis: (open: boolean, opaque: boolean) => {
    if (open) return tw`bg-honeydew duration-75`;
    if (opaque) return tw`bg-sonic duration-75`;
    return tw`bg-white duration-75`;
  },
  transBgColours: (open: boolean, opaque: boolean) => {
    if (open) return tw`bg-transparent`;
    if (opaque) return tw`bg-white duration-500`;
    return tw`bg-transparent delay-1000`;
  },
};

const Navbar: React.FC<{ opaque: boolean }> = ({ opaque }) => {
  const [open, setOpen] = useState(false);
  return (
    <header>
      <nav>
        <div
          tw="fixed top-0 left-0 z-50 w-full p-1.5 sm:p-2.5 text-center transition-colors duration-75"
          css={[styles.transBgColours(open, opaque)]}
        >
          <div tw="absolute top-1/2 -translate-y-1/2 left-4 sm:left-8">
            <BurgerMenu
              open={open}
              handleClick={() => setOpen((prev) => !prev)}
              opaque={opaque}
            />
          </div>
          <Link to="/">
            <Logo
              tw="inline-block w-12 h-12 transition-colors duration-200 fill-current sm:(w-20 h-20)"
              css={[styles.transTextColours(open, opaque)]}
            />
          </Link>
          <div
            className="group"
            tw="absolute right-4 top-1/2 -translate-y-1/2 sm:(right-8)"
          >
            <Link to="/locations">
              <h4
                tw="hidden md:block"
                css={[
                  styles.appointment,
                  styles.transTextColours(open, opaque),
                ]}
              >
                Book an appointment
              </h4>
              <h4
                tw="block md:hidden"
                css={[
                  styles.appointment,
                  styles.transTextColours(open, opaque),
                ]}
              >
                Book
              </h4>
              <div
                tw="w-full transition-all duration-300 invisible group-hover:visible h-0.5"
                css={[styles.fixThis(open, opaque)]}
              />
            </Link>
          </div>
        </div>
        <div
          tw="fixed inset-0 z-30 overflow-hidden transition-all bg-sonic max-h-0 duration-[600ms]"
          css={[open ? tw`max-h-full` : tw`delay-700`]}
        >
          <div tw="absolute w-full h-full px-2 pt-24 pb-12 -translate-x-1/2 -translate-y-1/2 sm:py-40 top-1/2 left-1/2 max-h-[1000px]">
            <ul tw="flex flex-col justify-between h-full font-display max-h-96 md:max-h-full">
              {navs.map(({ label, to }, i) => (
                <li
                  tw="text-2xl leading-10 text-center uppercase transition-opacity text-honeydew sm:text-[34px] letter-spacing[2px]"
                  css={{
                    transition: "opacity 0.3s ease",
                    transitionDelay: open
                      ? `${500 + i * 75}ms`
                      : `${navs.length * 50 - i * 50 + 150}ms`,
                    opacity: open ? 1 : 0,
                  }}
                  key={label}
                  onClick={() => setOpen(false)}
                >
                  <Link
                    to={to}
                    tw="transition-colors duration-300 border-transparent pb-0.5 border-b-[3px] hover:border-current"
                  >
                    {label}
                  </Link>
                </li>
              ))}
              <li
                tw="justify-center flex mt-4 space-x-4 transition-opacity duration-300"
                css={[
                  open ? tw`opacity-100` : tw`opacity-0`,
                  css({
                    transitionDelay: open
                      ? `${500 + (navs.length + 1) * 75}ms`
                      : "150ms",
                  }),
                ]}
                onClick={() => setOpen(false)}
              >
                <a
                  href="https://www.facebook.com/TheEarPlace/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookLogo tw="fill-current text-honeydew h-7 cursor-pointer" />
                </a>
                <a
                  href="https://www.instagram.com/the_ear_place/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramLogo tw="fill-current text-honeydew h-7 cursor-pointer" />
                </a>
                <a
                  href="https://twitter.com/the_ear_place"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterLogo tw="fill-current text-honeydew h-7 cursor-pointer" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
